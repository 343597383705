import '../scss/style.scss';

class StoryModal {
    constructor() {
        this.autoplayTimer = null;
        this.currentIndex = 0; // Global currentIndex
        this.isMuted = false; // Global mute state
        this.init();
    }

    init() {
        this.initializeNavigationButtons();
        this.bindEvents();
    }

    setCache(key, data, expirationInHours) {
        const expirationTime = new Date().getTime() + (expirationInHours * 60 * 60 * 1000);
        const cacheData = {
            data: data,
            expiration: expirationTime
        };
        localStorage.setItem(key, JSON.stringify(cacheData));
    }

    getCache(key) {
        const cacheData = JSON.parse(localStorage.getItem(key));
        if (!cacheData) return null;
        if (new Date().getTime() > cacheData.expiration) {
            localStorage.removeItem(key);
            return null;
        }
        return cacheData.data;
    }

    openModal(modal) {
        modal.style.display = 'block';
    }

    closeModal(modal) {
        modal.style.display = 'none';
        const modalSlider = modal.querySelector('.wp-stories__modal-slider');
        if (modalSlider) {
            modalSlider.innerHTML = '';
        }
    }

    bindEvents() {
        document.querySelectorAll('.wp-stories__modal-close, .wp-stories__item-modal-close').forEach(button => {
            button.addEventListener('click', (event) => {
                const modal = button.closest('.wp-stories__modal, .wp-stories__item-modal');
                this.closeModal(modal);
            });
        });

        document.body.addEventListener('click', (e) => {
            if (e.target.classList.contains('wp-stories__modal-slider')) {
                const modal = e.target.closest('.wp-stories__modal, .wp-stories__item-modal');
                this.closeModal(modal);
            }
        });

        window.addEventListener('click', (event) => {
            if (event.target.classList.contains('wp-stories__modal') || event.target.classList.contains('wp-stories__item-modal')) {
                this.closeModal(event.target);
            }
        });

        // Keyboard navigation
        window.addEventListener('keydown', (event) => {
            if (event.key === 'ArrowRight') {
                this.nextSlideHandler(event);
            } else if (event.key === 'ArrowLeft') {
                this.prevSlideHandler(event);
            }
        });

        // Mute/unmute button event
        document.addEventListener('click', (event) => {
            if (event.target.closest('.wp-story-item__header-button.wp-story-item__mute')) {
                event.preventDefault();
                event.stopPropagation();
                this.toggleMuteAllVideos();
            }
        });

        // Prevent clicks on header buttons from restarting the slide
        document.addEventListener('click', (event) => {
            if (event.target.closest('.wp-story-item__header-button')) {
                event.stopPropagation();
            }
        });
    }

    initializeStorySlider($storySlider) {
        if (!$storySlider) {
            return;
        }

        const $slides = $storySlider.querySelectorAll('.wp-story-slide');
        let localCurrentIndex = this.currentIndex; // Local variable for current index

        const showSlide = (index) => {
            $slides.forEach(slide => slide.classList.remove('active'));
            $slides[index].classList.add('active');

            // Stop all videos
            this.stopAndResetAllVideos();

            // Play video in the current slide
            const $video = $slides[index].querySelector('video');
            if ($video) {
                $video.play();
            }

            this.setProgressBar(index, $storySlider);
            startAutoplay(); // Restart autoplay with the updated slide
        };

        const nextSlide = () => {
            if (localCurrentIndex < $slides.length - 1) {
                localCurrentIndex++;
                this.currentIndex = localCurrentIndex; // Update global index
                showSlide(localCurrentIndex);
            } else {
                this.moveToNextStoryItem();
            }
        };

        const prevSlide = () => {
            if (localCurrentIndex > 0) {
                localCurrentIndex--;
                this.currentIndex = localCurrentIndex; // Update global index
                showSlide(localCurrentIndex);
            } else {
                this.moveToPrevStoryItem();
            }
        };

        const startAutoplay = () => {
            clearTimeout(this.autoplayTimer);
            const $currentSlide = $slides[localCurrentIndex];
            const duration = $currentSlide.querySelector('video') ? $currentSlide.querySelector('video').duration * 1000 : 5000;

            this.autoplayTimer = setTimeout(nextSlide, duration);
        };

        this.nextSlideHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            nextSlide();
        };

        this.prevSlideHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            prevSlide();
        };

        // Initialize progress bar items
        this.initializeProgressBarItems($storySlider, $slides.length);

        showSlide(localCurrentIndex);
        startAutoplay();
    }

    initializeProgressBarItems($storySlider, slideCount) {
        const $progressContainer = $storySlider.closest('.wp-story-item').querySelector('.wp-story-item__progress');
        $progressContainer.innerHTML = ''; // Clear existing items

        for (let i = 0; i < slideCount; i++) {
            const progressBarItem = document.createElement('div');
            progressBarItem.classList.add('wp-story-item__progress-item');
            const progressBarItemFill = document.createElement('div');
            progressBarItemFill.classList.add('wp-story-item__progress-item-fill');
            progressBarItem.appendChild(progressBarItemFill);
            $progressContainer.appendChild(progressBarItem);
        }
    }


    toggleMuteAllVideos() {
        this.isMuted = !this.isMuted;
        const videos = document.querySelectorAll('.wp-story-item video');
        videos.forEach(video => {
            video.muted = this.isMuted;
        });
        this.updateMuteButtons();
    }

    updateMuteButtons() {
        const muteButtons = document.querySelectorAll('.wp-story-item__header-button.wp-story-item__mute');
        muteButtons.forEach(button => {
            button.classList.toggle('muted', this.isMuted);
        });
    }

    setProgressBar(index, $storySlider) {
        const $progressBarItems = $storySlider.closest('.wp-story-item').querySelectorAll('.wp-story-item__progress-item');
        $progressBarItems.forEach((item, i) => {
            item.classList.remove('active', 'showed');
            const fill = item.querySelector('.wp-story-item__progress-item-fill');
            fill.style.animation = '';
            if (i < index) {
                item.classList.add('showed');
            } else if (i === index) {
                item.classList.add('active');
                const $currentSlide = $storySlider.querySelectorAll('.wp-story-slide')[index];
                const duration = $currentSlide.querySelector('video') ? $currentSlide.querySelector('video').duration : 5;
                fill.style.animation = `progress-bar-fill ${duration}s linear forwards`;
            }
        });
    }

    moveToNextStoryItem() {
        const $currentStoryItem = document.querySelector('.wp-story-item--active');
        const $nextStoryItem = $currentStoryItem.nextElementSibling;

        if ($nextStoryItem) {
            this.currentIndex = 0; // Reset global index for new story item
            $currentStoryItem.classList.remove('wp-story-item--active');
            $nextStoryItem.classList.add('wp-story-item--active');
            this.setStoryItemStyles();

            this.initializeStorySlider($nextStoryItem.querySelector('.wp-story-slider'));
        }
    }

    moveToPrevStoryItem() {
        const $currentStoryItem = document.querySelector('.wp-story-item--active');
        const $prevStoryItem = $currentStoryItem.previousElementSibling;

        if ($prevStoryItem) {
            this.currentIndex = 0; // Reset global index for new story item
            $currentStoryItem.classList.remove('wp-story-item--active');
            $prevStoryItem.classList.add('wp-story-item--active');
            this.setStoryItemStyles();

            this.initializeStorySlider($prevStoryItem.querySelector('.wp-story-slider'));
        }
    }

    stopAndResetAllVideos() {
        document.querySelectorAll('video').forEach(video => {
            video.pause();
            video.currentTime = 0;
        });
    }

    playVideoInCurrentSlide(storyItem) {
        const currentSlide = storyItem.querySelector('.wp-story-slide.active');
        if (currentSlide) {
            const video = currentSlide.querySelector('video');
            if (video) {
                video.play();
                video.muted = this.isMuted; // Apply current mute state
            }
        }
    }

    initializeMainSlider() {
        const initialStoryItem = document.querySelector('.wp-stories__modal-slider .wp-story-item--active');
        if (initialStoryItem) {
            const storySlider = initialStoryItem.querySelector('.wp-story-slider');
            if (storySlider) {
                this.initializeStorySlider(storySlider);
            }
        }

        this.setStoryItemStyles();
        window.addEventListener('resize', () => this.setStoryItemStyles());

        document.querySelectorAll('.wp-stories__modal-slider .wp-story-item .wp-story-item__preview').forEach(item => {
            item.addEventListener('click', (e) => {
                this.stopAndResetAllVideos();

                document.querySelectorAll('.wp-story-item--active').forEach(activeItem => {
                    activeItem.classList.remove('wp-story-item--active');
                });
                item.parentElement.classList.add('wp-story-item--active');

                const storySlider = item.parentElement.querySelector('.wp-story-slider');
                if (storySlider) {
                    this.initializeStorySlider(storySlider);
                }

                this.setStoryItemStyles();
                this.playVideoInCurrentSlide(item);
            });
        });

        this.checkViewedStories();
        this.updateMuteButtons(); // Ensure mute buttons reflect the current state
    }

    setStoryItemStyles() {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const activeItemAspectRatio = 0.562;
        const otherItemAspectRatio = 0.561;

        const activeItemHeight = windowHeight * 0.96; // 96% of window height
        const activeItemWidth = activeItemHeight * activeItemAspectRatio;

        const otherItemHeight = windowHeight * 0.38; // 38% of window height
        const otherItemWidth = otherItemHeight * otherItemAspectRatio;

        const gap = 40; // Gap between items

        const $items = document.querySelectorAll('.wp-stories__modal-slider .wp-story-item');
        const $activeItem = document.querySelector('.wp-story-item--active');
        const activeIndex = Array.from($items).indexOf($activeItem);

        // Center the active item
        $activeItem.style.transform = `translateX(calc(-50% + ${windowWidth / 2}px))`;
        $activeItem.style.width = `${activeItemWidth}px`;
        $activeItem.style.height = `${activeItemHeight}px`;
        $activeItem.style.position = 'absolute';
        $activeItem.style.left = '0';

        // Position previous items
        let leftOffset = windowWidth / 2 - activeItemWidth / 2 - gap;
        for (let i = activeIndex - 1; i >= 0; i--) {
            $items[i].style.transform = `translateX(calc(-50% + ${leftOffset - otherItemWidth / 2}px))`;
            $items[i].style.width = `${otherItemWidth}px`;
            $items[i].style.height = `${otherItemHeight}px`;
            $items[i].style.position = 'absolute';
            $items[i].style.left = '0';
            leftOffset -= otherItemWidth + gap;
        }

        // Position next items
        leftOffset = windowWidth / 2 + activeItemWidth / 2 + gap;
        for (let j = activeIndex + 1; j < $items.length; j++) {
            $items[j].style.transform = `translateX(calc(-50% + ${leftOffset + otherItemWidth / 2}px))`;
            $items[j].style.width = `${otherItemWidth}px`;
            $items[j].style.height = `${otherItemHeight}px`;
            $items[j].style.position = 'absolute';
            $items[j].style.left = '0';
            leftOffset += otherItemWidth + gap;
        }
    }

    loadStories(story_id, $button) {
        const cacheKey = `stories_${story_id}`;

        fetch(ajaxurl, {
            method: 'POST',
            body: new URLSearchParams({
                action: 'load_stories',
                story_id: story_id
            })
        }).then(response => response.text()).then(response => {
            document.querySelector('.wp-stories__modal-slider').innerHTML = response;
            this.initializeMainSlider();
            this.openModal(document.getElementById('storyModal'));
            $button.classList.add('showed');
            this.markAsViewed(story_id);
            this.setCache(cacheKey, response, 6); // Cache the response for 6 hours

            document.querySelectorAll('.wp-stories__modal .custom-next').forEach(button => {
                button.addEventListener('click', (event) => {
                    this.nextSlideHandler(event);
                });
            })

            document.querySelectorAll('.wp-stories__modal .custom-prev').forEach(button => {
                button.addEventListener('click', (event) => {
                    this.prevSlideHandler(event);
                });
            });

        });
    }

    markAsViewed(story_id) {
        let viewedStories = JSON.parse(localStorage.getItem('viewedStories')) || [];
        if (!viewedStories.includes(story_id)) {
            viewedStories.push(story_id);
            localStorage.setItem('viewedStories', JSON.stringify(viewedStories));
        }
    }

    checkViewedStories() {
        let viewedStories = JSON.parse(localStorage.getItem('viewedStories')) || [];
        viewedStories.forEach(story_id => {
            const storyCategory = document.querySelector(`.wp-stories__category[data-story_id="${story_id}"]`);
            if (storyCategory) {
                storyCategory.classList.add('viewed');
            }
        });
    }


    initializeNavigationButtons() {
        const wp_stories = document.querySelector('.wp-stories');
        if (!wp_stories) return;

        const wp_stories_wrapper = wp_stories.parentElement;
        const wp_stories_next_btn = wp_stories_wrapper.querySelector('.wp-stories__next');
        const wp_stories_prev_btn = wp_stories_wrapper.querySelector('.wp-stories__prev');
        const wp_stories_width = wp_stories_wrapper.offsetWidth; // Width of the visible area
        const wp_story_category_width = wp_stories.querySelector('.wp-stories__category').offsetWidth + 19;
        const wp_stories_quantity = wp_stories.querySelectorAll('.wp-stories__category').length;
        const wp_stories_categories_width = wp_story_category_width * wp_stories_quantity;

        // Amount to scroll (e.g., show 3 stories at a time)
        const scrollAmount = wp_story_category_width * 3;

        const updateButtonStates = () => {
            const current_position = wp_stories.scrollLeft;

            // Check if we are within 20-30 pixels of the end
            if (current_position + wp_stories_width >= wp_stories_categories_width - 30) {
                wp_stories_next_btn.classList.remove('wp-stories__next--active');
            } else {
                wp_stories_next_btn.classList.add('wp-stories__next--active');
            }

            if (current_position <= 0) {
                wp_stories_prev_btn.classList.remove('wp-stories__prev--active');
            } else {
                wp_stories_prev_btn.classList.add('wp-stories__prev--active');
            }
        };

        // Initial check to hide/show buttons
        if (wp_stories_width >= wp_stories_categories_width) {
            wp_stories_next_btn.classList.remove('wp-stories__next--active');
            wp_stories_prev_btn.classList.remove('wp-stories__prev--active');
        } else {
            wp_stories_next_btn.classList.add('wp-stories__next--active');
        }

        wp_stories_next_btn.addEventListener('click', (e) => {
            e.preventDefault();

            const current_position = wp_stories.scrollLeft;
            const new_position = Math.min(current_position + scrollAmount, wp_stories_categories_width - wp_stories_width);

            wp_stories.scrollTo({ left: new_position, behavior: 'smooth' });

            // Update button states after scroll animation
            setTimeout(updateButtonStates, 500);
        });

        wp_stories_prev_btn.addEventListener('click', (e) => {
            e.preventDefault();

            const current_position = wp_stories.scrollLeft;
            const new_position = Math.max(current_position - scrollAmount, 0);

            wp_stories.scrollTo({ left: new_position, behavior: 'smooth' });

            // Update button states after scroll animation
            setTimeout(updateButtonStates, 500);
        });

        wp_stories.addEventListener('scroll', () => {
            updateButtonStates();
        });

        // Update button states on resize
        window.addEventListener('resize', () => {
            const wp_stories_width = wp_stories_wrapper.offsetWidth;
            if (wp_stories_width >= wp_stories_categories_width) {
                wp_stories_next_btn.classList.remove('wp-stories__next--active');
                wp_stories_prev_btn.classList.remove('wp-stories__prev--active');
            } else {
                updateButtonStates();
            }
        });

        // Initial button state update
        updateButtonStates();
    }


}

// Initialize the StoryModal class when the document is ready
document.addEventListener('DOMContentLoaded', () => {
    const storyModal = new StoryModal();

    document.querySelectorAll('.wp-stories__category').forEach(category => {
        category.addEventListener('click', () => {
            const story_id = category.getAttribute('data-story_id');
            storyModal.loadStories(story_id, category);
        });
    });

    storyModal.checkViewedStories();
});
